import axios from 'axios';
import { X_API_KEY_C, X_API_KEY_P } from './constants';
import { jwtToken } from '../../stores/jwt';
import { ErrorCodes } from '../../types/types';
import { GLOBAL_ERROR } from '../../constants';
import userJWT from '../../stores/user-jwt';

export const dispatchError = (code: ErrorCodes) => {
    const globalError = new CustomEvent(GLOBAL_ERROR, {
        detail: {
            code
        }
    })
    document.dispatchEvent(globalError);
}

const Axios = axios.create({
    baseURL: import.meta.env.VITE_API_HOSTNAME,
    headers: {
        'Content-type': 'application/json',
        'x-api-key-c': X_API_KEY_C,
        'x-api-key-p': X_API_KEY_P
    }
});

Axios.interceptors.response.use(
    (response) => {
        if (response.config.method === 'get' && response.config.url?.includes('ignore=token')) {
            return response;
        }
        if (response.headers['x-authorization'] && response.headers['x-authorization'].length > 40) {
            let token = '';
            if (response.headers['x-authorization'].includes('Bearer')) {
                token = `${response.headers['x-authorization']}`;
            } else {
                token = `Bearer ${response.headers['x-authorization']}`;
            }
            jwtToken.set({ token: token });
            userJWT.set({ token: token });
            const auth = { token: token };
            localStorage.setItem('uat', JSON.stringify(auth));
        }

        return response;
    },
    (error) => {
        if (error?.response?.status === ErrorCodes.InternalServer) {
            dispatchError(ErrorCodes.InternalServer);
        }
        return Promise.reject(error);
    }
);

export default Axios;